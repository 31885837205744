import React, { useEffect, useState } from "react";
import Modal from 'react-modal';
import { useNavigate } from "react-router-dom";
import {
  FilterVariant,
  FilterVariantWrapper,
  ShoppingPageTitle,
  ShoppingPageWrapper,
  Title,
  VariantsWrapper,
  YesButton
} from './ShoppingPage.styles';
import axios from "axios";
import { BackButton } from "@vkruglikov/react-telegram-web-app";
import { ReactComponent as CloseIcon } from './close-icon.svg';
import { ReactComponent as RubleSvg } from "../ShoppingPage/ruble.svg";
import serviceImage from "../ShoppingPage/5dbdc0835911016e2d380125.jpg";
import { FadeAnimation } from "../../ui-kit/FadeAnimation";
import { AbsolutePreloader } from "../../ui-kit/Preloader";
import { ReactComponent as CheckBox } from "../ShoppingPage/checkbox.svg";
import { ReactComponent as UncheckBox } from "../ShoppingPage/Uncheckbox.svg";
import { Base64 } from 'js-base64';

// @ts-ignore
const tg = window.Telegram.WebApp;

Modal.setAppElement('#root');

interface Service {
  id: any;
  name: string;
  price: string;
}

export const ShoppingPage = () => {
  const navigate = useNavigate();

  const [servicesAbonement, setServicesAbonement] = useState<Service[]>([]);
  const [servicesPersonal, setServicesPersonal] = useState<Service[]>([]);
  const [selectedService, setSelectedService] = useState<Service | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orderId, setOrderId] = useState<number | null>(null);
  const [clientDid, setClientDid] = useState<number | null>(null);
  const [isChecked, setIsChecked] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [formClicked, setFormClicked] = useState(false);
  const [nextButtonLoading, setNextButtonLoading] = useState(false);
  const [clubId, setClubId] = useState<string| number | null>(null)

  const handleGetBack = () => {
    if (localStorage.getItem('token')) {
      navigate('/profile');
    } else {
      navigate('/');
    }
  };

  const fetchServicesAbonement = async () => {
    const clubId = localStorage.getItem('club') || sessionStorage.getItem('club');
    setClubId(clubId)
    try {
      const response = await axios.get(`https://api.profitnes.club/api/services/get-services?type=1&clubId=${clubId}`);
      setServicesAbonement(response.data.data);
    } catch (error) {
      console.error('Failed to fetch services', error);
    }
  };

  const fetchServicesPersonal = async () => {
    const clubId = localStorage.getItem('club') || sessionStorage.getItem('club');
    try {
      const response = await axios.get(`https://api.profitnes.club/api/services/get-services?type=2&clubId=${clubId}`);
      setServicesPersonal(response.data.data);
    } catch (error) {
      console.error('Failed to fetch services', error);
    }
  };

  const openModal = (service: Service) => {
    setSelectedService(service);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsChecked(true);
    setIsModalOpen(false);
    setSelectedService(null);
  };

  const handleGoShoppingCart = async () => {
    setNextButtonLoading(true);
    setFormClicked(true);

    if (!isChecked) {
      setHasError(true);
      return;
    }

    if (!selectedService) {
      console.error('No service selected');
      return;
    }

    let postData;
    let url;
    if (sessionStorage.getItem('club')) {
      url = 'https://api.profitnes.club/api/create-order-new';
      postData = {
        service_id: selectedService.id,
        club: sessionStorage.getItem('club'),
        surname: sessionStorage.getItem('surname'),
        name: sessionStorage.getItem('name'),
        fathername: sessionStorage.getItem('fathername'),
        phone: sessionStorage.getItem('phone'),
        passport: sessionStorage.getItem('passport'),
        email: sessionStorage.getItem('email'),
        tg_chat: tg?.initDataUnsafe?.user?.id
      };
    } else {
      url = 'https://api.profitnes.club/api/create-order';
      postData = {
        service_id: selectedService.id,
        tg_chat: tg?.initDataUnsafe?.user?.id
      };
    }

    try {
      const response = await axios.post(
          url,
          postData,
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
              'Content-type': 'application/json',
            }
          }
      );

      const data = response.data;
      const { id, user_id } = data.data;

      setOrderId(id);
      setClientDid(user_id);

      // Now that the order is created, encode the data and open the URL
      const dataString = `${id}|${user_id}|${selectedService.price}|${clubId}`;
      const base64EncodedData = Base64.encode(dataString);
      const serviceName = encodeURI(selectedService.name);

      console.log('я тут')

      const urlCart = `/shopping-cart?token=${base64EncodedData}&name=${serviceName}`;
      navigate(urlCart)
      setNextButtonLoading(false);

    } catch (error) {
      console.error('Failed to create order', error);
      setHasError(true);
    }
  };

  useEffect(() => {
    fetchServicesAbonement();
    fetchServicesPersonal();
  }, []);


return (
    <>
      <FadeAnimation show={Boolean(!(servicesPersonal.length > 0 || servicesAbonement.length > 0))}>
        <AbsolutePreloader/>
      </FadeAnimation>
      <FadeAnimation show={Boolean((servicesPersonal.length > 0 || servicesAbonement.length > 0))}>
        <ShoppingPageWrapper>
          {formClicked ?
              <>
              </> :
              <>
                <BackButton onClick={handleGetBack}/>
              </>}
          <ShoppingPageTitle>Магазин услуг</ShoppingPageTitle>
          <VariantsWrapper>
            <Title>
              Абонементы
            </Title>
            {servicesAbonement.map(service => (
                <FilterVariantWrapper key={service.name} onClick={() => openModal(service)}>
                  <FilterVariant>{service.name}</FilterVariant>
                  <FilterVariant style={{justifyContent: 'end'}}>{parseFloat(service.price).toLocaleString('ru-RU')}</FilterVariant>
                  <RubleSvg style={{width: "18px"}}/>
                </FilterVariantWrapper>
            ))}
          {/*</VariantsWrapper>*/}
          {/*<VariantsWrapper>*/}
            <Title>
              Персональные тренировки
            </Title>
            {servicesPersonal.map(service => (
                <FilterVariantWrapper key={service.name} onClick={() => openModal(service)}>
                  <FilterVariant>{service.name}</FilterVariant>
                  <FilterVariant style={{justifyContent: 'end'}}>{parseFloat(service.price).toLocaleString('ru-RU')}</FilterVariant>
                  <RubleSvg style={{width: "18px"}}/>
                </FilterVariantWrapper>
            ))}
          </VariantsWrapper>
          <Modal
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              contentLabel="Оплата"

              style={{
                content: {
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)',
                  padding: '20px',
                  borderRadius: '14px',
                  width: '370px',
                  border: "none",
                  backgroundColor: '#2C2C2E',
                },

                overlay: {
                  backgroundColor: 'rgba(0, 0, 0, 0.75)' // темный фон за модальным окном
                }
              }}
          >
            {selectedService && (
                <div>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}>
                    <h2 style={{textAlign: 'left', color: 'white'}}>Оплата</h2>
                    <CloseIcon
                        onClick={closeModal}
                        style={{
                          cursor: 'pointer'
                        }}
                    />
                  </div>
                  <img
                      src={serviceImage}
                      alt="Service"
                      style={{
                        display: 'block',
                        margin: '10px auto',
                        borderRadius: '14px',
                        width: '100%',
                        height: '150px',
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                  />
                  <h3 style={{textAlign: 'left', color: 'white', marginBottom: '10px'}}>{selectedService.name}</h3>
                  <div
                      style={{
                        border: '1px solid #F5C71A',
                        borderRadius: '14px',
                        padding: '10px',
                        marginBottom: '10px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        color: '#F5C71A',
                      }}
                  >
                    <span>Стоимость</span>
                    <span>{parseInt(selectedService.price, 10).toLocaleString('ru-RU')}</span>

                  </div>
                  <div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
                    <div onClick={() => setIsChecked(!isChecked)} style={{cursor: 'pointer', marginRight: '10px'}}>
                      {isChecked ? <CheckBox/> :
                          <UncheckBox style={{border: 'solid 1px #505050', borderRadius: '8px'}}/>}
                    </div>
                    <p style={{
                      fontSize: '12px',
                      textAlign: 'start',
                      width: '100%',
                      color: hasError ? 'red' : '#969696'
                    }}>
                      Оплачивая, вы принимаете условия{' '}
                      <a href="https://api.profitnes.club/storage/dogovor_profitness.pdf"
                         target="_blank" rel="noopener noreferrer"
                         style={{fontWeight: 'bold', textDecoration: 'underline'}}>
                        сублицензионного договора-оферты
                      </a>
                    </p>
                  </div>
                  <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '20px'}}>
                    <button
                        type="button"
                        onClick={closeModal}
                        style={{
                          padding: '10px 20px',
                          borderRadius: '14px',
                          border: 'none',
                          width: '40%',
                          background: '#fff',
                          color: '#2C2C2E',
                          fontWeight: '600'
                        }}
                    >
                      Закрыть
                    </button>
                    <YesButton
                        onClick={handleGoShoppingCart}
                        isLoading={nextButtonLoading}
                        variant={'primary'}>
                      Далее
                    </YesButton>
                  </div>
                </div>
            )}
          </Modal>

        </ShoppingPageWrapper>
      </FadeAnimation>
    </>
  );
};